<template>
    <div class="details-container">
        <div class="column first">
            <ViewItemsFullDetailsComponent/>
        </div>
        <div class="column second">
           <OpportunityRightSidebar :selectedCourse="selectedCourse"  showTitle isCheckout />
        </div>

        <!-- MODALS -->
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import OpportunityRightSidebar from '@/modules/opportunity-details/components/OpportunityRightSidebar.vue';
import ViewItemsFullDetailsComponent from '@/modules/opportunity-details/components/ViewItemsFullDetailsComponent.vue';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';

export default defineComponent({
  components: {
    ViewItemsFullDetailsComponent,
    OpportunityRightSidebar,
  },

  data() {
    return {
      selectedCourse: null
    };
  },

  computed: {
    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse']),
  },

  created() {
    if (!this.currentCourse) {
      this.routeToCourses();
    }

    this.selectedCourse = this.currentCourse;
  },

  methods: {
    ...mapActions(OPPORTUNITY_STORE, ['setCourses']),

    routeToCourses() {
      this.$router.push({ name: 'opportunity/courses' });
    },
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;
.details-container {
    display:flex;
    justify-content: space-between;
    // height: calc(100% + 250px);
    margin-left: 10px;
    margin-right: 20px;
    gap: 2rem;
    max-width: 1230px;

    .column {
        display:flex;
        gap: 2rem;
    }
    .first {
        width: 70%;
    }
    .second {
        width: 30%;
        // height: calc(100% + 50px);
    }
}
@include media-sm-max-width {
    .details-container {
        margin-right: 10px;
        padding: 30px 20px;
        flex-direction:column;
        .first {
            width: 100%;
        }
        .second {
            width: 100%;
            padding-bottom: 30px;
        }
    }
}
@include media-lg-max-width {
    .details-container {
        padding: 0 20px 30px 20px;
        .second {
            padding-bottom: 110px;
        }
    }
}
</style>
