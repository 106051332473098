<template>
    <div class="view-full-details-container">
        <el-divider></el-divider>
        <el-scrollbar>
           <div class="view-full-details-container__content">
               <!-- <DetailsHeroComponent hideDetails :title="currentCourse?.title" />
               <DetailsDescriptionComponent title="About" :description="currentCourse?.about"/>
               <DetailsDescriptionComponent title="Course Aims" description="This qualification prepares learners who are looking towards a career in Air Source Heat Pump Installation and gaining the necessary skills for the design, installation, testing, commissioning, handover, servicing and fault-finding of ground and air source heat pump systems in accordance with the latest NOS/QCF criteria and MCS scheme requirements."/>
               <DetailsListToLearnComponent/> -->

              <DetailTemplateComponent :tag="currentCourse?.about" :referenceId="currentCourse?.referenceId" />

           </div>
        </el-scrollbar>
    </div>
</template>
<script>

import { mapGetters } from 'vuex';

// import DetailsDescriptionComponent from '@/modules/opportunity-details/components/DetailsDescriptionComponent.vue';
// import DetailsGuestsComponent from '@/modules/opportunity-details/components/DetailsGuestsComponent.vue';
// import DetailsHeroComponent from '@/modules/opportunity-details/components/DetailsHeroComponent.vue';
// import DetailsListToLearnComponent from '@/modules/opportunity-details/components/DetailsListToLearnComponent.vue';
import DetailTemplateComponent from '@/modules/opportunity-details/components/DetailTemplateComponent.vue';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';

export default {
  components: {
    // DetailsHeroComponent,
    // DetailsDescriptionComponent,
    // DetailsListToLearnComponent,
    // DetailsGuestsComponent

    DetailTemplateComponent
  },

  computed: {
    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse']),
  },
};
</script>
<style lang="scss" scoped>
.view-full-details-container {
    width: 97%;
    height: 100%;
    position: relative;
   .el-divider {
    //    position: absolute;
    //    top: 0;
       height: 8px;
       width: 100%;
       background: #FFA500;
       border-radius: 100px;
       margin: 10px 0 24px 0;
       position: sticky;
       top: 0;
       z-index: 2;
   }
   .el-scrollbar {
        // background: #fff;
        width: 100%;
        // border-radius: 12px;
        box-sizing: border-box;
        padding: 0;
        height: 100%;

        :deep(.el-scrollbar__view) {
           padding: 0;
        }
        // height: calc(100% - 200px);
    }

    &__content {
        display:flex;
        flex-direction: column;
        gap:2rem;
        padding-bottom: 4rem;
    }
}
</style>
